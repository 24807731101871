import revive_payload_client_arqP7T9jah from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@planetscale+database@1.16.0_@types+node@20.12.2_@unocss+re_ojsao64qrp4ax3vrmmkpvrmb6i/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_tATjnz2Wlq from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@planetscale+database@1.16.0_@types+node@20.12.2_@unocss+re_ojsao64qrp4ax3vrmmkpvrmb6i/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_5xyB4wqSMh from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@planetscale+database@1.16.0_@types+node@20.12.2_@unocss+re_ojsao64qrp4ax3vrmmkpvrmb6i/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_mR76x2d2AW from "/app/node_modules/.pnpm/nuxt-site-config@2.2.12_whwfucza5n3xx53mxoivaurwd4/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_j1vEsIHjLI from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@planetscale+database@1.16.0_@types+node@20.12.2_@unocss+re_ojsao64qrp4ax3vrmmkpvrmb6i/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_FU7HcYvb6Z from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@planetscale+database@1.16.0_@types+node@20.12.2_@unocss+re_ojsao64qrp4ax3vrmmkpvrmb6i/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/app/packages/kassebil/.nuxt/components.plugin.mjs";
import prefetch_client_EhxpIFFFMw from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@planetscale+database@1.16.0_@types+node@20.12.2_@unocss+re_ojsao64qrp4ax3vrmmkpvrmb6i/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_5b1k87e30Q from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_uo7rkgzurkhgj5gzgg2hwfels4/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_8kICfHrIDG from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_uo7rkgzurkhgj5gzgg2hwfels4/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_TTCn4OKz5t from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_@nuxt+devtools@1.1.5_mife2h4nzd64yzhpmwn2vkqaju__@unocss+webp_gwlov4a5plbgemuag7ntr2qiu4/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_kxJrN8yLHE from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_@nuxt+devtools@1.1.5_mife2h4nzd64yzhpmwn2vkqaju__@unocss+webp_gwlov4a5plbgemuag7ntr2qiu4/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import slideovers_JID7o4Xop5 from "/app/node_modules/.pnpm/@nuxt+ui@2.15.2_focus-trap@7.5.4_nuxt@3.12.2_@parcel+watcher@2.4.1_@planetscale+database@1.16_4gavgasuzyt5j6xsb2qil4iy7a/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_ek8Mxtmq1j from "/app/node_modules/.pnpm/@nuxt+ui@2.15.2_focus-trap@7.5.4_nuxt@3.12.2_@parcel+watcher@2.4.1_@planetscale+database@1.16_4gavgasuzyt5j6xsb2qil4iy7a/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_UMQcyRrYzf from "/app/node_modules/.pnpm/@nuxt+ui@2.15.2_focus-trap@7.5.4_nuxt@3.12.2_@parcel+watcher@2.4.1_@planetscale+database@1.16_4gavgasuzyt5j6xsb2qil4iy7a/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_vI1nMzrLLU from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.0_rollup@4.14.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_VgSJHKtPpJ from "/app/node_modules/.pnpm/nuxt3-meta-pixel@1.0.9_rollup@4.14.3/node_modules/nuxt3-meta-pixel/dist/runtime/plugin.mjs";
import plugin_2BUEQtGTCa from "/app/node_modules/.pnpm/@dargmuesli+nuxt-cookie-control@7.5.1_rollup@4.14.3_webpack@5.91.0/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.mjs";
import plugin_client_Vsw9wm9QpF from "/app/node_modules/.pnpm/@nuxtjs+plausible@0.2.4_rollup@4.14.3/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.mjs";
import view_transitions_client_TloTU9VT4U from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@planetscale+database@1.16.0_@types+node@20.12.2_@unocss+re_ojsao64qrp4ax3vrmmkpvrmb6i/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_2BcEApR0GP from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@planetscale+database@1.16.0_@types+node@20.12.2_@unocss+re_ojsao64qrp4ax3vrmmkpvrmb6i/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import presets_S5xwhMb3fZ from "/app/node_modules/.pnpm/@nuxt+ui-pro@1.2.0_focus-trap@7.5.4_nuxt@3.12.2_@parcel+watcher@2.4.1_@planetscale+database@1_ag4hta4bukr5ysautqkgxid4py/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import scrollbars_client_PLT9NUve6p from "/app/node_modules/.pnpm/@nuxt+ui-pro@1.2.0_focus-trap@7.5.4_nuxt@3.12.2_@parcel+watcher@2.4.1_@planetscale+database@1_ag4hta4bukr5ysautqkgxid4py/node_modules/@nuxt/ui-pro/plugins/scrollbars.client.ts";
import variables_UoONeEQGsS from "/app/node_modules/.pnpm/@nuxt+ui-pro@1.2.0_focus-trap@7.5.4_nuxt@3.12.2_@parcel+watcher@2.4.1_@planetscale+database@1_ag4hta4bukr5ysautqkgxid4py/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import error_handler_kEP5FliEXj from "/app/packages/kassebil/plugins/error-handler.ts";
import facebook_pixel_client_DZvV2r4W6e from "/app/packages/kassebil/plugins/facebook-pixel.client.ts";
import posthog_client_fUucxKWhZE from "/app/packages/kassebil/plugins/posthog.client.ts";
import sentry_client_shVUlIjFLk from "/app/packages/kassebil/plugins/sentry.client.ts";
import vue_gtm_client_stBjLl0OeM from "/app/packages/kassebil/plugins/vue-gtm.client.ts";
export default [
  revive_payload_client_arqP7T9jah,
  unhead_tATjnz2Wlq,
  router_5xyB4wqSMh,
  _0_siteConfig_mR76x2d2AW,
  payload_client_j1vEsIHjLI,
  check_outdated_build_client_FU7HcYvb6Z,
  components_plugin_KR1HBZs4kY,
  prefetch_client_EhxpIFFFMw,
  titles_5b1k87e30Q,
  defaults_8kICfHrIDG,
  siteConfig_TTCn4OKz5t,
  inferSeoMetaPlugin_kxJrN8yLHE,
  slideovers_JID7o4Xop5,
  modals_ek8Mxtmq1j,
  colors_UMQcyRrYzf,
  plugin_client_vI1nMzrLLU,
  plugin_VgSJHKtPpJ,
  plugin_2BUEQtGTCa,
  plugin_client_Vsw9wm9QpF,
  view_transitions_client_TloTU9VT4U,
  chunk_reload_client_2BcEApR0GP,
  presets_S5xwhMb3fZ,
  scrollbars_client_PLT9NUve6p,
  variables_UoONeEQGsS,
  error_handler_kEP5FliEXj,
  facebook_pixel_client_DZvV2r4W6e,
  posthog_client_fUucxKWhZE,
  sentry_client_shVUlIjFLk,
  vue_gtm_client_stBjLl0OeM
]