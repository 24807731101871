import routerOptions0 from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@planetscale+database@1.16.0_@types+node@20.12.2_@unocss+re_ojsao64qrp4ax3vrmmkpvrmb6i/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/app/packages/kassebil/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}